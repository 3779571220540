import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import HeroFullScreenPhoto from '../components/sections/hero-full-screen-photo'
import WhyThinkPlus from '../components/sections/why-think-plus'
import OurPartners from '../components/sections/our-partners'
import Testimonials from '../components/sections/testimonials-static'
import ContactForm from '../components/sections/contact-form'

import '../styles/pages/page-landingpage.scss'
import '../styles/pages/page-mailchimpexperts.scss'
import { mailchimpexperts as servicesSchema } from '../utils/schema/services'

const pageMailchimpexperts = ({ location }) => {
  const data = useStaticQuery(graphql`{
  ghostPage: ghostPage(tags: {elemMatch: {name: {eq: "#page-mailchimpexperts"}}}) {
    ...GhostPageFields
  }
  hero: file(relativePath: {eq: "hero/hero-mailchimpexperts.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, layout: FULL_WIDTH)
    }
  }
  monkey: file(relativePath: {eq: "mailchimpexperts/monkey.png"}) {
    childImageSharp {
      gatsbyImageData(width: 400, layout: CONSTRAINED)
    }
  }
  macbook: file(relativePath: {eq: "mailchimpexperts/macbook.png"}) {
    childImageSharp {
      gatsbyImageData(width: 870, layout: CONSTRAINED)
    }
  }
  iphones: file(relativePath: {eq: "mailchimpexperts/iphones.png"}) {
    childImageSharp {
      gatsbyImageData(width: 870, layout: CONSTRAINED)
    }
  }
  newsletter: file(relativePath: {eq: "mailchimpexperts/newsletters.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`)
	const page = data.ghostPage

  return <>
    <MetaData
      data={data}
      location={location}
      type="website"
    />
    <Helmet>
      <style type="text/css">{`${page.codeinjection_styles}`}</style>
      <script type="application/ld+json">{JSON.stringify(servicesSchema)}</script>
    </Helmet>
    <Layout viewport={'full'}>
      <article className="landing-page">
        <HeroFullScreenPhoto
          heroImage={data.hero.childImageSharp.gatsbyImageData}
          heroContent={
            `<div class="landing-page__heading">
                <h1>MAILCHIMP CERTIFIED EXPERTS</h1>
                <p class="h3">Athens | Greece</p>
              </div>
              <div class="landing-page__heading-cta">
                <p class="h6 dec-element--line-horizontal">Επικοινωνήστε μαζί μας +30 210 6101478 <a href="tel: +302106101478" class="btn__theme ml-md-3 mt-3 mt-md-0">Καλέστε μας</a></p>
                <p class="h6 dec-element--line-horizontal">Ή συμπληρώστε τα πεδία της φόρμας για να επικοινωνήσουμε άμεσα μαζί σας <a href="#contact_form_section" class="btn__theme ml-md-3 mt-3 mt-md-0">Φόρμα Επικοινωνίας</a></p>
              </div>`
          }
        />
        <section className="text-align-center">
          <div className="mailchimp__intro background__theme">
            <div className="container">
              <GatsbyImage image={data.monkey.childImageSharp.gatsbyImageData} alt="Mailchimp" />
              <h2>Το email Marketing που ταιριάζει σε εσάς,<br /> δουλεύει και αποδίδει για εσάς.</h2>
            </div>
          </div>
          <div className="container small">
            <p>Η επιτυχία μιας καμπάνιας εξαρτάται από την πολύ καλά σχεδιασμένη στρατηγική επικοινωνίας και τον έξυπνο συνδυασμό ενεργειών μέχρι και την τελευταία λεπτομέρεια.</p>
            <p>Το Mailchimp είναι ένα από τα μεγαλύτερα εργαλεία για το σχεδιασμό και την αποστολή εντυπωσιακών μηνυμάτων ηλεκτρονικού ταχυδρομείου, τη διαχείριση των συνδρομητών σας και την παρακολούθηση της απόδοσης της καμπάνιας. Ως πιστοποιημένοι mailchimp experts, προσφέρουμε προσαρμοσμένες λύσεις για την κάλυψη των δικών σας επιχειρηματικών αναγκών.</p>
            <GatsbyImage image={data.macbook.childImageSharp.gatsbyImageData} alt="" />
            <p>H Think Plus βρίσκεται ανάμεσα στις κορυφαίες πιστοποιημένες εταιρείες στην Ελλάδα, διαθέτοντας απόλυτη εξειδίκευση στην πλατφόρμα και τα εργαλεία που προσφέρει το Mailchimp για ένα αποτελεσματικό email marketing.</p>
            <p>Μέσα από την πολυετή εμπειρία αλλά και τη συνεχή εκπαίδευση στα σύγχρονα εργαλεία marketing, σχεδιάζουμε καμπάνιες email marketing που αποδίδουν τα μέγιστα, τόσο για την εταιρεία σας και για τα προϊόντα σας.</p>
          </div>
        </section>
        <section className="mailchimp__together background__theme text-align-center">
          <h2 className="h5">Είμαστε δίπλα σας σε κάθε βήμα:</h2>
          <h3>Ξεκινώντας</h3>
          <p className="h6">Πως να δημιουργήσετε τον λογαριασμό σας και να χτίσετε την πρώτη σας λίστα</p>
          <h3>Στρατηγική</h3>
          <p className="h6">Πως θα πετύχετε πραγματικά σωστά αποτελέσματα στο κοινό που στοχεύετε</p>
          <h3>Σχεδιασμός</h3>
          <p className="h6">Σχεδιάζουμε εντυπωσιακό περιεχόμενο φιλικό προς όλες τις σύγχρονες συσκευές</p>
          <h3>Ενσωμάτωση</h3>
          <p className="h6">Συνδέουμε και συγχρονίζουμε τα δεδομένα με το website και το eshop σας</p>
          <h3>Επαλήθευση λίστας</h3>
          <p className="h6">Προστατεύουμε την ικανότητα αποστολής και τη φήμη της εταιρείας σας</p>
          <h3>Εκπαίδευση</h3>
          <p className="h6">Προσφέρουμε εξατομικευμένη εκπαίδευση για τη χρήση του Mailchimp</p>
          <h3>Ολοκληρωμένη υπηρεσία</h3>
          <p className="h6">Είμαστε δίπλα σας για όλη τη διαχείριση της καμπάνιας σας</p>
        </section>
        <section className="container small mailchimp__contactus text-align-center">
          <Link to="/contact-us" className="btn btn__theme">Επικοινωνήστε μαζί μας σήμερα</Link>
          <GatsbyImage image={data.iphones.childImageSharp.gatsbyImageData} alt="" />
          <p>Αρκετές επιχειρήσεις και επαγγελματίες αρκούνται στο δωρεάν πρόγραμμα του Mailchimp όταν ξεκινούν για πρώτη φορά. Καθώς όμως η λίστα των συνδρομητών αρχίζει να αυξάνεται, ίσως χρειαστεί να αναβαθμίσετε το μηνιαίο πλάνο του λογαριασμού σας. Το πρόγραμμα έκπτωσης Mailchimp Experts παρέχει στους νέους συνδρομητικούς λογαριασμούς τους 3 πρώτους μήνες δωρεάν, στα πλαίσια της συνεργασίας σας με την Think Plus. Tο mailchimp προσφέρει το ποσό της έκπτωσης αυτής, σε μη κερδοσκοπικούς οργανισμούς.</p>
          <p>Έχουμε τη γνώση και την εμπερία να σας προτείνουμε το κατάλληλο πλάνο, το πρόγραμμα που ανταποκρίνεται στις δικές σας ανάγκες.</p>
        </section>
        <div className="background__theme">
          <div className="container">
            <GatsbyImage image={data.newsletter.childImageSharp.gatsbyImageData} alt="" />
          </div>
        </div>
        <OurPartners headerTitle="ΠΕΛΑΤΕΣ" />
        <Testimonials classname="background__theme--dark" />
        <WhyThinkPlus />
        <ContactForm />
      </article>
    </Layout>
  </>;
}

export default pageMailchimpexperts
